.medias-gallery {
  display: flex;
  align-items: stretch;
  height: 100%;
  padding-top: 10px;
  &-menu {
    width: $sidebar-width * 0.75;
    position: relative;
    overflow: hidden;
    height: 100%;
    & .menu-header {
      padding: .5rem .9375rem .625rem;
      font-weight: 500;
      font-size: .8125rem;
    }
    & .menu-item {
      & .menu-icon {
        margin-right: 8px !important;
      }
      & a {
        padding: .3125rem .9375rem;
        display: block;
        color: $color-font-light;
        text-decoration: none;
        &.menu-item-active {
          background-color: #1f6bff;
          color: #fff;
        }
      }
    }
  }

  .medias-list {
    border-left: 1px solid $color-border;
    height: 100%;
    min-height: 100vh;
    width: 100%;
  }
}

.medias {
  & img {
    width: 100%;
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    cursor: pointer;
    user-select: none;
    -webkit-user-drag: none;
  }
  & .upload-invite {
    width: 100%;
    padding: .25rem;
    cursor: pointer;
    height: 100%;
    font-size: 12px;
    color: $color-font-grey-light;
    text-align: center;
    //margin-top: 34px;
    & div {
      margin-bottom: 4px;
    }
    &:hover {
      color: $color-font-grey;
    }
  }

  & input {
    position: absolute;
    left: 30px;
    top: 15px;
    cursor: pointer;
  }

  & .media-info {
    padding: 2px 4px;
    position: absolute;
    right: 0px;
    top: -15px;
    cursor: pointer;
  }
}