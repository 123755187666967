.table {
  & thead th {
    border-top: none;
    border-bottom: 1px solid $color-font;
  }
  & a {
    text-decoration: underline;
  }
  & .form-group {
    width: 100%;
  }
}