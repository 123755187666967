.dropdown {
  & .dropdown-item-text {
    background-color: $color-brand-light;
    color: $color-brand;
    font-size: 14px;
    margin-bottom: .7rem;
  }

  & .dropdown-item {
    width: 100%;
    padding: 6px 20px;
    clear: both;
    font-weight: 400;
    color: $color-font;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    & .dropdown-icon{
      margin-left: auto;
    }
    &:hover {
      color: #1e2432;
      background-color: $color-background-hover;
    }
  }
}