.input-group {
  & .input-group-prepend {
    & .input-group-text {
      background-color: $color-background-secondary;
      border: none;
    }
  }
} 

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: $color-white;
  background-clip: padding-box;
  border: 1px solid $color-border;
  appearance: none;
  border-radius: 6px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}