.app-header {
  border: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 0;
  z-index: 1030;
  height: 60px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 6px 6px rgb(31 107 255 / 10%);

  & .logo {
    width: $sidebar-width;
    height: $header-height;
    display: flex;
    align-items: center;
    font-weight: 700;
    color: $color-font;
    font-size: 20px;
    text-decoration: none;
    height: 60px;
    padding: 20px 0;
    padding-left: 22px;
  }

  & .tools {
    display: flex;
    flex: 1;
    align-items: center;
    &-search {
      margin: 0 auto 0 50px;
      position: relative;
      max-width: 760px;
      flex: 1;
      & .form-group {
        margin-bottom: 0 !important;
        & .form-control {
          background: $color-background;
          border-color: transparent;
          padding-left: 46px;
          height: 40px;
          font-size: 1rem;
        }
      }
    }
    &-item {
      position: relative;
      & .dropdown .menu-link {
        height: $header-height;
        padding: 20px;
        text-decoration: none;
        color: $color-font;
        display: flex;
        align-items: center;
        font-weight: 600;
        &.user-dropdown {
          border-radius: 50%;
          background-color: $color-background;
          padding: 14px;
          display: inline;
          margin-right: 22px;
          color: $color-font-secondary;
        }
      }
    }
  }

  //color: $color-font-grey;
  //box-shadow: -2px 2px 8px -7px $color-box-shadow;
  //padding: 10px;
  //margin-bottom: 20px;
  //background-color: $color-white;
  //border-radius: $border-radius;
  //&-user {
  //  border-left: 3px solid $color-brand-light;
  //  padding-left: 1.5rem;
  //}
  //& .user-dropdown {
  //  text-align: right;
  //  color: $color-font-grey;
  //  border-radius: 0;
  //  &::after {
  //    display: none;
  //  }
  //}
  //&-title {
  //  margin: 0;
  //  padding-left: 0.75rem;
  //  padding-right: 0.75rem;
  //  margin-right: 0.75rem;
  //  border-right: 3px solid $color-brand-light;
//
  //}
}