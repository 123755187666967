.sidebar {
  position: fixed;
  top: $header-height;
  left: 0;
  bottom: 0;
  width: $sidebar-width;
  border-right: 1px solid $color-border;
  z-index: 1020;
  display: flex;
  flex-direction: column;
  &-content {
    padding: 10px 0;
    width: auto;
    overflow: hidden;
    flex: 1;
  }
  & .menu {
    &-header {
      margin: 8px 20px;
      font-size: .7875rem;
      font-weight: 600;
      color: $color-font-light;
    }
    &-item {
      padding: 0 10px;
      & a {
        padding: 6px 10px;
        line-height: 20px;
        text-decoration: none;
        color: $color-font-secondary;
        display: flex;
        align-items: center;
        border-radius: $border-radius;
        &:hover {
          background-color: rgba(201,210,227,.5);
        }
        &.menu-item-active {
          color: $link-color;
          font-weight: 600;
        }

        & .menu-item-icon {
          width: 1.25rem;
          height: 1.25rem;
          font-size: 1rem;
          color: $color-font-secondary;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &-text {
      padding-left: 0.625rem;
    }

  }
}


//.sidebar {
//  width: 270px;
//  position: absolute;
//  left: 0;
//  top: 0;
//  bottom: 0;
//  background: $color-background-black;
//  z-index: 1010;
//  & .pro-sidebar-inner {
//    background: $color-background-black;
//  }
//  &_header {
//    padding: 24px;
//    text-transform: uppercase;
//    font-weight: bold;
//    font-size: 14;
//    letter-spacing: 1px;
//    overflow: hidden;
//    text-overflow: ellipsis;
//    white-space: nowrap;
//  }
//  min-height: 100vh;
//}
//
//.sidebar-toggle {
//  position: absolute;
//  top: 10px;
//  right: 10px;
//  z-index: 9999;
//  @media (min-width: $breakpoint-md) {
//    display: none;
//  }
//}