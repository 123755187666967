//Brand
$color-brand: #162899;
$color-brand-dark: #151567;
$color-brand-light: #eff0f8;
$color-brand-secondary: #3c4e71;


//Colors
$color-white: #fff;
$color-black: #1a2229;

//Status
$color-success: #28a745;
$color-error: #de425b;
$color-warning: rgb(194, 126, 25);

//Backgrounds
$color-background: #ebeef4;
$color-background-secondary: $color-brand-light;
$color-background-dark: $color-brand-secondary;
$color-background-black: $color-black;
$color-background-grey: #eff0f7;
$color-background-hover: #f0f2f6;

//Fonts
$color-font: #212837;
$color-font-secondary: #3c4e71;
$color-font-light: #657eae;
$color-font-dark: $color-brand-dark;
$color-font-grey: #b6b6d0;
$color-font-grey-light: #9f9f9f;
$color-font-brand: $color-brand;
$color-font-brand-light: #c499d4;
$color-font-secondary: $color-brand-secondary;
$color-link: #1f6bff;
$color-font-xlight: rgba(255, 255, 255, 0.5);
$color-font-title: $color-brand-secondary;
$color-font-strong: #404040;

//Shadows
$color-box-shadow: rgb(31 107 255 / 10%);
$box-shadow: 0 6px 6px rgb(31 107 255 / 10%);

//Borders
$color-border: #dae0ec;
$color-border-light: #e1e4eb;


$color-btn-primary: #1f6bff;
$color-btn-secondary: #c9d2e3;
$color-btn-reverse: $color-white;


.u-color-secondary {
  color: $color-font-secondary;
}
.u-color-light {
  color: $color-font-light;
}



$colors: (
  default: $color-font,
  brand: $color-font-brand,
  secondary: $color-font-secondary,
  title: $color-font-title,
  strong: $color-font-strong,
  light: $color-font-light,
  xlight: $color-font-xlight,
  success: $color-success,
  error: $color-error,
  grey-light: $color-font-grey
)


