//Settings
@import './settings/colors';
@import './settings/sizes';
@import './settings/common';

//Lib
@import './lib/custom-bootstrap';
@import './lib/custom-pro-sidebar';
@import './lib/custom-froala';
@import './lib/custom-react-quill';
@import './lib/custom-editor-js';

//Containers
@import './containers/page';
@import './containers/app';
@import './containers/medias';

//Components
@import './components/bootstrap/card';
@import './components/bootstrap/dropdown';
@import './components/bootstrap/input';
@import './components/bootstrap/table';
@import './components/bootstrap/table';
@import './components/form';
@import './components/sidebar';
@import './components/dropzone';
@import './components/header';
@import './components/rightbar';
@import './components/product';

body {
  font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-weight: 400;
  color: $color-font;
  background-color: $color-background;
}

.error {
  color: $color-error;
}

#app {
  position: relative;
}

a {
  text-decoration: none;
}