.rightbar {
  top: 90px;
  position: sticky;
  border-left: 1px solid $color-border;
  padding: 0;
  & .nav {
    width: 100%;
    flex-direction: column;
    & .nav-link {
      color: $color-font-light;
      padding: 3px 24px;
      &.active {
        color: $color-font;
        font-weight: 600;
      }
    }
  }
}