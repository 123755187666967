.auto-save-form {
  & form {
    display: inline-block;
  }
  display: inline-block;
  & .form-group {
    margin-bottom: 0;
    display: inline-block;
  }
  &__actions {
    margin-left: 8px;
    display: inline-block;
    & svg {
      cursor: pointer;
    }
  }

  width: 100%;
  & form {
    width: 100%;
  }
}

.auto-save-form-non-editable {
  cursor: pointer;

  & .edit-icon {
    font-size: 10px;
    color: $color-font-grey-light;
    margin-left: 4px;
  }

  & .label {
    margin-right: 4px;
  }

  &.disabled {
    cursor: default;
  }
}

.tree-select-fields {
  & .form-group {
    display: inline-block;
    margin-left: 8px;
    margin-bottom: 2px;
  }
  & .tree-select-field {
    & svg {
      cursor: pointer;
    }
    & .actions {
      display: none;
      margin-left: 18px;
      & .btn {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    &:hover {
      & .actions {
        display: inline-block;
      }
    }

  }
}

.form-check {
  cursor: pointer;
  & input, & label {
    cursor: pointer;
  }
}
.custom-control {
  z-index: 0;
}