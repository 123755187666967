.overlay-dropzone {
  position: fixed;
  width: 100vw;
  /* margin-left: -40px; */
  height: 100vh;
  background-color: #000000ad;
  top: 0;
  left: 0;
  z-index: 1100;
  cursor: pointer;
  & .input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  color: $color-font-light;
  text-align: center;
  font-size: 28px;
  &.hide {
    opacity: 0;
    z-index: -1;
    cursor: default;
  }
  & .in-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    & div {
      height: initial !important;
    }
  }
}