//Theme
$theme-colors: (
  'primary': $color-btn-primary,
  'secondary': $color-btn-secondary
);
//Link
$link-color: #1f6bff;

//Breadcrumb
$breadcrumb-divider-color: $color-font-secondary;
$breadcrumb-active-color: $color-font-secondary;
$breadcrumb-bg: transparent;
$breadcrumb-border-radius: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 0;
.breadcrumb {
  font-weight: 700;
}

//Card
$card-bg: $color-white;
$card-cap-bg: $color-white;
$card-border-radius: 9px;

//Table
$table-border-color: $color-border;

//Form

//List group
.list-group {
  & .auto-save-form {
    padding: 0;
    margin: 0;
    width: 100%;
    & .form-group {
      width: 95%;

    }
  }
}

//Buttons
.btn.btn-default {
  color: $color-font-secondary;
  background-color: $color-white;
  border-color: $color-btn-secondary;
  &:hover {
    background-color: $color-background;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(180 190 210 / 50%);
  }
}

//Mark
$mark-bg: transparent;
$mark-padding: 0;
mark {
  font-weight: 700;
  color: inherit;
}

//Input
$input-border-color: $color-border;
$input-color: $color-font;
$input-padding-x: .75rem;
$input-padding-y: .375rem;

//Badges
.badge-light {
  color: $color-font-light !important;
  background-color: $color-background-secondary !important;
}

@import '../../../../node_modules/bootstrap/scss/bootstrap.scss';


.list-group.list-group-flush .list-group-item {
  padding: 8px 0;
  border: none;
}

.carousel-item img {
  max-width: 90%;
}
.active.carousel-item{
  display: flex;
  align-items: center;
}


.carousel-inner {
  height: 100%;
}

.carousel-control-prev, .carousel-control-next {
  width: 5% !important;
}

a.link {
  cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $color-success !important;
  background-color: $color-success !important;
}