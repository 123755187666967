.app {
  color: $color-font;
  .content {
    margin-left: $sidebar-width;
    margin-top: $header-height;
    padding: 30px;
    @media (max-width: $breakpoint-md) {
      margin-left: 0;
    }
  }
}