.page {
  &-centered-content {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    & .content {
      flex: 1 1;
    }

    &.centered-form {
      & .card {
        min-width: 400px;
      }
      & .form-header {
        width: 400px;
        font-size: 28px;
        padding: 0;
        margin: 0 auto 15px;
        position: relative;
        display: flex;
        align-items: center;
        max-width: 100%;
        & .title {
          & small {
            display: block;
            font-size: 14px;
            display: block;
            color: $color-font-grey;
          }
        }
        & .icon {
          margin-left: auto;
          color: rgba(45,53,60,.15);
        }
      }
  
      & .form-body {
        padding: 30px;
        background: $color-background-dark;
        &__content {
          padding: 0;
          color: $color-font-light;
          width: 400px;
          margin: 0 auto;      
        }
        & a {
          color: $color-font-brand-light;
        }
      }

      & .form-footer {
        margin: 15px auto 0;
        width: 400px;
      }
      & .btn {
        margin-top: 0 !important;
        float: right;
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
}

.page-tools-bar {
  display: flex !important;
  width: 90%;
  margin: auto;
  margin-top: -20px !important;
  background-color: $color-background-grey;
  padding: 12px;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  & .form-group {
    margin-bottom: 0;
  }
  & .input-group .input-group-prepend .input-group-text {
    border: 1px solid $border-color;
  }
}